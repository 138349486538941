<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">所属ユーザー管理</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading != 2" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div>
              <a-form-model
                ref="ruleForm"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item label="所属会社" :labelCol="{span: 3}" :wrapperCol="{span: 21}">
                  <a-checkbox-group
                    v-model="activeCompanies"
                  >
                    <a-col :xs="12" :sm="6" v-for="item in allCompanies" :key="item.value">
                      <a-checkbox :key="item.value" :value="item.value">{{ item.label }}</a-checkbox>
                    </a-col>
                  </a-checkbox-group>
                </a-form-model-item>
                <div class="text-center">
                  <a-button type="primary" @click="onSubmit" :loading="btnLoading" :class="$style.filledBtn">
                    更新
                  </a-button>
                  <router-link :to="{ name: 'companyGroupShow', params: { id: $route.params.id } }">
                    <a-button style="margin-left: 10px;">
                      戻る
                    </a-button>
                  </router-link>
                </div>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'

export default {
  data() {
    return {
      loading: 0,
      btnLoading: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      allCompanies: [],
      belongCompanies: [],
      activeCompanies: [],
    }
  },
  created() {
    const resultGroupCompanies = Vue.prototype.$api.send('get', 'company_groups/' + this.$route.params.id + '/companies')
    resultGroupCompanies.then(response => {
      this.loading++
      this.belongCompanies = response.map((item) => {
        this.activeCompanies.push(item.id)
        return item.id
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 所属会社の取得に失敗しました。',
        })
        this.loading++
      })

    const resultAllCompanies = Vue.prototype.$api.send('get', 'companies')
    resultAllCompanies.then(response => {
      this.loading++
      this.allCompanies = response.map((item) => { return { label: item.id + ': ' + item.name, value: item.id, checked: this.belongCompanies.includes(item.id) } })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社の取得に失敗しました。',
        })
        this.loading++
      })
  },
  methods: {
    onSubmit() {
      this.btnLoading = true
      const _this = this
      const send = Vue.prototype.$api.send('put', 'company_groups/' + this.$route.params.id + '/companies', { companies: this.activeCompanies })
      send.then(response => {
        _this.$notification['success']({
          message: '更新しました。',
        })
        _this.$router.push({ name: 'companyGroupShow', params: { id: this.$route.params.id } })
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 更新に失敗しました。',
          })
          this.btnLoading = false
        })
    },
  },
}

</script>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
h2 {
  font: normal normal bold 14px/33px $v2-font-family;
  letter-spacing: 0px;
}
</style>
