var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.loading != 2
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    attrs: {
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol
                    }
                  },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "所属会社",
                          labelCol: { span: 3 },
                          wrapperCol: { span: 21 }
                        }
                      },
                      [
                        _c(
                          "a-checkbox-group",
                          {
                            model: {
                              value: _vm.activeCompanies,
                              callback: function($$v) {
                                _vm.activeCompanies = $$v
                              },
                              expression: "activeCompanies"
                            }
                          },
                          _vm._l(_vm.allCompanies, function(item) {
                            return _c(
                              "a-col",
                              { key: item.value, attrs: { xs: 12, sm: 6 } },
                              [
                                _c(
                                  "a-checkbox",
                                  {
                                    key: item.value,
                                    attrs: { value: item.value }
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "a-button",
                          {
                            class: _vm.$style.filledBtn,
                            attrs: { type: "primary", loading: _vm.btnLoading },
                            on: { click: _vm.onSubmit }
                          },
                          [_vm._v("\n                  更新\n                ")]
                        ),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "companyGroupShow",
                                params: { id: _vm.$route.params.id }
                              }
                            }
                          },
                          [
                            _c(
                              "a-button",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  "\n                    戻る\n                  "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("所属ユーザー管理")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }